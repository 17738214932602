export const OnboardingWizardBaseUrl = '/personalized-video-readings';

export const wizardScreenPaths = {
  aboutMe: `${ OnboardingWizardBaseUrl }/about-me`,
  question: `${ OnboardingWizardBaseUrl }/question`,
  advisorSelection: `${ OnboardingWizardBaseUrl }/advisor-selection`,
  payment: `${ OnboardingWizardBaseUrl }/payments`,
  whatsNext: `${ OnboardingWizardBaseUrl }/whats-next`
};

export const generalPaths = {
  reading: `${ OnboardingWizardBaseUrl }/view-order/:web_order_token`
};

export const screenPaths = {
  welcome: OnboardingWizardBaseUrl,
  ...generalPaths,
  wizard: { ...wizardScreenPaths }
};

export const screenNames = {
  welcome: 'welcome',
  wizard:{
    aboutMe: 'aboutMe',
    question: 'question',
    advisorSelection: 'advisorSelection',
    payment: 'payment',
    whatsNext: 'whatsNext'
  },
  reading: 'reading'
};

export const routes = {
  [screenNames.welcome]: {
    path: screenPaths.welcome,
    name: screenNames.welcome
  },
  [screenNames.wizard.aboutMe]: {
    path: screenPaths.wizard.aboutMe,
    name: screenNames.wizard.aboutMe
  },
  [screenNames.wizard.question]: {
    path: screenPaths.wizard.question,
    name: screenNames.wizard.question
  },
  [screenNames.wizard.advisorSelection]: {
    path: screenPaths.wizard.advisorSelection,
    name: screenNames.wizard.advisorSelection
  },
  [screenNames.wizard.payment]: {
    path: screenPaths.wizard.payment,
    name: screenNames.wizard.payment
  },
  [screenNames.wizard.whatsNext]: {
    path: screenPaths.wizard.whatsNext,
    name: screenNames.wizard.whatsNext
  },
  [screenNames.reading]: {
    path: screenPaths.reading,
    name: screenNames.reading
  }
};
