import React, {
  Suspense, lazy
} from 'react';
import { createRoot } from 'react-dom/client';
import { OnboardingWizardBaseUrl } from './poweb_app/onboarding/components/routes/routes_const';

const PowebApps = {
  default: {
    app: lazy(() => import('./poweb_app'))
  },
  onboardingWizard: {
    baseUrl: OnboardingWizardBaseUrl,
    app: lazy(() => import('./poweb_app/onboarding'))
  }
};

const Application = () => {
  const path = window.location.pathname;

  const selectedApp = Object.values(PowebApps).find(appConfig => (!!appConfig.baseUrl && path.startsWith(appConfig.baseUrl)));

  const SelectedAppComponent = selectedApp?.app || PowebApps.default.app;

  return (
    <Suspense fallback={ <></> }>
      <SelectedAppComponent />
    </Suspense>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('app-root');
  const root = createRoot(container);
  root.render(<Application />);
});
